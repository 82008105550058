import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
// import styled from "@emotion/styled";
import { css } from '@emotion/core'
import { ThemeProvider } from 'emotion-theming'
import GatsbyImage from 'gatsby-image'
import GatsbyBackgroundImage from 'gatsby-background-image'
import { Link } from "gatsby"

// slider stuff
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Slider from 'react-slick'
import OcmaColorsBar from '../components/OcmaColorsBar'

 import spotlightImage from '../images/spotlightImage.png'
// import holidaySpecialImage from '../images/holiday-special.png'
// import megaphoneImage from '../images/megaphone.png'
// import happyHolidaysImage from '../images/happy-holidays.png'

// import Link from '../components/Link'
import Layout from '../components/layout'
import SEO from '../components/seo'
import styles from '../constants/theme'

import LogoWhite from '../images/LogoWhite.svg'
import Conference from '../images/conf-2021-2.png'
import cf2022 from '../images/cf2022.png'
import cf2022group from '../images/OCMA2022.jpeg'
import cf2023group from '../images/OCMA2023.jpg'
import cf2024group from '../images/OCMA2024.jpg'
import logo2023 from '../images/OCMA2023_.jpg'
import Ocma2024fall from '../images/OCMA_Fall_2024_PD.jpg'
import img2023 from '../images/unnamed.png'
import img20231 from '../images/unnamed1.jpg'
import MaksimSokolov from '../images/MaksimSokolov.jpeg'
import JoshuaEmmanuel from '../images/JoshuaEmmanuel.jpeg'
import topsum2023 from '../images/topsum2023.png'
import harmony from '../images/Harmony.jpeg'
import Text from '../components/Text'
import StyledLink from '../components/StyledLink'
import HeroBG from '../components/HeroBG'
import MainContentWrap from '../components/MainContentWrap'
import FeatLink from '../components/FeatLink'
import ContentSection from '../components/ContentSection'
import SubscribeModalContextConsumer from '../components/SubscribeModalContext'
// let downloadvideo = 'https://u.vretta.ca/ocmt-2021-winter-pd.mp4'

import HeroImage2021PD1 from '../images/HeroImage2021PD1.png'
import Spring2021PD2 from '../images/Spring2021PD2.png'
import Virtual from '../images/virtual.jpeg'
import Inperson from '../images/in-person.jpeg'
import Stephanie from '../images/Stephanie_McKean.jpg'
import home20241 from '../images/2024-OCMA-Conference.jpeg'
const imageContainerStyles = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width:80%;
`;

const roundedImageStyles = css`
  border-radius: 5%;
  margin-right: 10px;
`;
const sliderSettings = {
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  variableWidth: true,
  autoplay: true,
  autoplaySpeed: 3000,
  // lazy: true,
  centerMode: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        // variableWidth: false,
        centerMode: false,
      },
    },
  ],
}

export default function IndexPage() {
  const indexPageQuery = useStaticQuery(graphql`
    query indexPageQuery {
      wordpressPage(title: { eq: "Home" }) {
        acf {
          hero_image {
            localFile {
              childImageSharp {
                fluid(quality: 90, maxWidth: 1920, toFormat: JPG) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          promo {
            on
            content
          }
          conference_callout_image {
            localFile {
              childImageSharp {
                fluid(quality: 90, maxWidth: 665, toFormat: JPG) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          gallery {
            localFile {
              childImageSharp {
                fixed(width: 285, height: 285, toFormat: JPG, quality: 80) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
          mission_text
          conference_callout_header
          conference_callout_subheader
          conference_callout_paragraph
          conference_callout_page_slug
          assiciation_section_header
          assicoation_section_text
          gallery_section {
            heading
            event_title
            link
          }
          subscribe_text
        }
      }
      site {
        siteMetadata {
          description
        }
      }
    }
  `)

  const { acf } = indexPageQuery.wordpressPage
  let tempContent = `
    <div class="text-center">
    <h1>Spring 2021 PD Event</h1>  <p>Thursday, June 3rd  10:00 am - 12:00 pm</p>
    <img src='${Spring2021PD2}' alt=''/>
     <br/><br/> <a href='/2021pd2' style="color: white; text-align: center; font-weight: bold; font-size: 24px; background-color: black; width: auto; margin: 0 auto; padding: 1% 2% 1% 2%; border-radius: 40px; text-transform: uppercase; display: inline-block;">Learn More</a>
      <br/><br/>
      <a target="_blank" href='https://docs.google.com/forms/d/e/1FAIpQLSenjhocZzw5jgiTy-Jly6em3xeRYXw2Rcp9BFvqZokDgVtUFA/viewform ' style="color: white; text-align: center; font-weight: bold; font-size: 24px; background-color: #2270ee; width: auto; margin: 0 auto; padding: 1% 2% 1% 2%; border-radius: 40px; text-transform: uppercase; display: inline-block;">REGISTER</a>
      
    </div>
  `

  tempContent = ''
  return (
    <Layout>
      <SEO title="Home" keywords={['']} />
      <HeroBG imageURL={acf.hero_image.localFile.childImageSharp.fluid} />
      <ThemeProvider theme={styles.palette.blue}>
        <div
          className="container text-center"
          css={css`
            position: relative;
            padding-top: calc(40vh - ${styles.nav.height});
            margin-bottom: ${styles.margin.large};
          `}
        >
          <img
            src={LogoWhite}
            alt=""
            css={css`
              margin-bottom: ${styles.margin.small};
            `}
          />
          <Text pFeat>{indexPageQuery.site.siteMetadata.description}</Text>
        </div>
      </ThemeProvider>
      <MainContentWrap>
        <div className="row justify-content-center">
          <div className="col-md-10">
            <ThemeProvider theme={styles.palette.white}>
              {acf.promo.on && (
                <div
                  dangerouslySetInnerHTML={{ __html: tempContent }}
                  css={css`
                    & img {
                      height: unset;
                      width: 100%;
                    }
                    & .megaphone {
                      width: 30%;
                      margin: 0 auto;
                    }
                  `}
                />
              )}

              <ContentSection
                css={css`
                  text-align: center;
                  margin-top: 30px;
                `}
              >
                {' '}
                {/* <div className="text-center">
                  <h1>5 months, 5𝜋/6, -.87+.50j</h1>
                  <img alt="" src={Conference} />
                  <p>
                    ... whatever representation you chose, we need to rotate the
                    date of the 2021 OCMA Conference to October 25 - 27, 2021.
                    Why? Quite simply we miss being with you and we think that
                    the Fall will be our best chance to have an event with some
                    sort of in-person component. But don't worry, all
                    participants will have the ability to join remotely if
                    in-person doesn't work for them. This is a one-time change;
                    we expect to be back to our annual May conference in 2022.
                  </p>
                </div>
                <br />
                <br /> */}
                
                
                <div>
  {/* <h1>OCMA Conference 2024: IGNITE</h1>
  <br /> */}
  <img alt="" src={img2023} />
  <br /><br />
</div>
<div className="mcnTextContent" style={{padding: '18px', color: '#343434', fontFamily: 'Helvetica', fontWeight: 'bold', fontSize: '31px', textAlign: 'center', lineHeight: '150%', margin: '0', wordBreak: 'break-word'}}>
You are in the spotlight!
  </div>
  <div>
  <br />
  <img alt="" src={spotlightImage} style={{maxWidth: '90%'}} />
  <br /><br />
</div>
<p style={{fontFamily: 'Helvetica', fontSize:' 16px', lineHeight: '125%', textAlign: 'left', maxWidth: '90%',  marginLeft: 'auto',  marginRight: 'auto'}}>
We can’t seem to escape from the disruptive nature of AI, so what will we do about it in terms of math assessment? 
                  </p> 
                  <p style={{fontFamily: 'Helvetica', fontSize:'18px'}}>Join your OCMA colleagues in a PD session on</p>

                  <p style={{fontFamily: 'Helvetica, Helvetica Neue', fontSize:'32px'}}>Math Assessment in the Early AI Era
                  <br /><span style={{fontFamily: 'Helvetica', fontSize:'18px'}}>presented by</span><br />
                  You!
                  </p>
                  <div className="mcnTextContent" style={{padding: '18px', color: '#343434', fontFamily: 'Helvetica', fontSize: '16px', textAlign: 'left', lineHeight: '150%', margin: '0', wordBreak: 'break-word'}}>
                  This PD event will have a lot discussion and sharing of current practices. Do you only accept in-person assessments? Have you explicitly banned certain tools / apps and found a way to identify if students continue to use them? Do you encourage ethical AI use in assessments?
                  <br /><br />
                  If you want to <span style={{ fontWeight: 'bold', textDecoration:'underline'}} >take the spotlight</span> to explain how you are responding to AI use in math assessment, let us know when you sign up for the PD and we’ll set aside 10 minutes for you to go through your philosophy / technique. If you don’t want to be in the spotlight, register anyway -  after each spotlighter has presented, all participants will have an opportunity to ask questions and discuss how this would or wouldn’t work in their classes
                  <br />  <br />
                 <hr style={{ height:'1.5px', backgroundColor:'#333', color:'#333', border: 'none' }}></hr>
                  </div>
                 
  <div
    style={{ fontFamily: 'Helvetica', fontSize:' 16px', lineHeight: '150%', textAlign: 'center', maxWidth: '90%',  marginLeft: 'auto',  marginRight: 'auto' }}
  >
      <strong>Date:</strong> Wednesday, February 26th, 2025
<br></br>
      <strong>Time:</strong> 1:00 pm - 2:30 pm
      <br></br>
      <strong>Location:</strong> Zoom
      <br></br>
      <strong>Cost:</strong> None, covered by OCMA
    <p>
      <br />
    </p>
    <p>
      Spotlighters need to register by February 7th but participant registration will continue until February 21st.
    </p>

  </div>
  <StyledLink
                  h4
                  border
                  to="https://docs.google.com/forms/d/e/1FAIpQLSfPHOds9a8Ir-eNVqEUzh0O1fW-dWOWNCvCtV1DGPKHEwkveg/viewform"
                  css={css`
                  background-color:#142454;border-radius:50px;border:2px solid #000000;color:#ffffff;display:block;font-family:'Helvetica Neue', Helvetica, Arial, Verdana, sans-serif;font-size:24px;font-weight:normal;font-style:normal;padding:16px 28px;text-decoration:none;min-width:30px;text-align:center;direction:ltr;letter-spacing:0px
                `}
                >
                  Register for PD or Sign Up to Spotlight
                </StyledLink>
                <br /><br />
  <hr style={{ height:'2px', backgroundColor:'#333', color:'#333', border: 'none', maxWidth: '97%'}}></hr>
                   {/* <div className="mcnTextContentContainer" style={{maxWidth: '80%', backgroundColor: '#FFD249', border: '1px dotted', borderCollapse: 'collapse', padding: '0'}}>
     
    </div>  */}
    
     <div className="mcnTextContent" style={{ color: '#343434', fontFamily: 'Helvetica', fontSize: '22px', textAlign: 'center', lineHeight: '150%', margin: '0', wordBreak: 'break-word', maxWidth: '90%',  marginLeft: 'auto',  marginRight: 'auto', paddingTop:'9%'}}>
     It is the <b>harmony</b> of the diverse parts,<br />
     their <b>symmetry</b>,<br />
     their happy <b>balance</b>;<br />
     in a word it is all that introduces <b>order</b>,<br />
     all that gives <b>unity</b>,<br />
     that permits us to see clearly and to comprehend at once<br />
     both the ensemble and the details.<br />
    <p style={{fontSize:'18px',fontWeight: 'bold'}}>Henri Poincaré</p>
      </div>
    <div style={{ textAlign: 'center' }}>
        <img alt="OCMA 2024" src={harmony}  style={{maxWidth: '90%', marginTop:'2%'}}/>
    </div>
                  
                
                  <p style={{fontFamily: 'Helvetica', fontSize:' 22px', lineHeight: '125%', textAlign: 'center', marginTop:'2%'}}>
                  In your practice, do you:
                  </p> 
                  <p style={{fontFamily: 'Helvetica', fontSize:' 22px', textAlign: 'center',  lineHeight: '125%'}}>
                  <b>amplify</b> concepts with exciting tools and techniques?<br />

<b>shift</b> old habits towards mathematical and statistical thinking?<br />

<b>periodically</b> find yourself stuck in a pedagogical valley?<br />

harmonize students on multiple <b>wavelengths</b> towards success?<br />
                  </p> 
                  <p style={{fontFamily: 'Helvetica', fontSize:' 22px', textAlign: 'center', lineHeight: '125%'}}>
                  Celebrate how you encourage <b>harmony</b> in your class at the<br />
                  2025 OCMA Conference held <b><u>May 21-23, 2025</u></b> at Fern <br />
                  Resort, Orillia.
                  </p> 
                  <br></br> 
  <hr></hr>
  <br></br> 
                  
                  {/*  
                  <p style={{fontFamily: 'Helvetica', fontSize:' 16px', lineHeight: '125%'}}>
                  Packing a suitcase with proofs and lore,<br />
                  They saw in the conference a boundless store.<br />
                  Their students, like embers, would catch the spark,<br />
                  A perpetual fire, lighting up the dark.<br />
                  </p> 

<br></br> */}
               

                 

<div class="text-center"
style={{
        width: '80%',
        marginLeft: 'auto',
        marginRight: 'auto'
      }}
> 
    </div>
    <p style={{fontFamily: 'Helvetica', fontSize:' 16px', textAlign: 'center',  lineHeight: '125%'}}>
    If you would like to present, please submit a brief topic title and short summary<br />
     explaining your ideas using the submit button below. Presentations will be 1 hour<br />
      including questions. Both individual and joint proposals are welcome! Submissions<br />
       are due by <b><u>January 31st, 2025</u></b>.
  </p> 
  {/* <div className="mcnTextContent" style={{ color: '#343434', fontFamily: 'Helvetica', fontSize: '14px', fontWeight: 'bold', textAlign: 'left', lineHeight: '150%', margin: '0', wordBreak: 'break-word'}}>
  <strong>Date:</strong> Tuesday October 22nd, 2024 <br />
  <strong>Time:</strong> 10:00 am - 11:30 am <br />
  <strong>Location:</strong> Zoom <br />
  <strong>Cost:</strong> None, covered by OCMA <br />
  <br /><br />
</div> */}

{/* <div className="mcnTextContent" style={{ color: '#343434', fontFamily: 'Helvetica', fontSize: '14px', fontWeight: 'normal', textAlign: 'center', lineHeight: '150%', margin: '0', wordBreak: 'break-word'}}>
Part of the presentation will be modelling a community circle; registrants will be sent materials 
approximately one week prior to the PD session.
</div> */}
<br />

<StyledLink
                  h4
                  border
                  to="https://forms.gle/PXDE63Svj6MeeGxV9"
                  css={css`
                  background-color:#000000;border-radius:50px;border:2px solid #000000;color:#ffffff;display:block;font-family:'Helvetica Neue', Helvetica, Arial, Verdana, sans-serif;font-size:24px;font-weight:normal;font-style:normal;padding:16px 28px;text-decoration:none;min-width:30px;text-align:center;direction:ltr;letter-spacing:0px
                `}
                >
                  Submit your idea now!
                </StyledLink>
                <br />
                <br />
                <hr />
                <br />
                <br />
                <br />
                <br />

  {/*
<div
      style={{
        width: '80%',
        marginLeft: 'auto',
        marginRight: 'auto',
        fontSize: '16px',
        display: 'block',
        color: 'black',
        textAlign: 'justify',
      }}
      >
      <div 
      style={{
        fontSize: '26px',
        display: 'block',
        color: 'black',
      }}
     ><strong>
        Recruit - you have your orders....</strong>
      </div>
      <div 
      style={{
        fontSize: '16px',
        display: 'block',
        color: 'black',
      }}
     >
      You are to present yourself at Fern Resort, Orillia by 12:00 May 24, 2023 at the Top Sum Elite Mathematics School as part of the 2023 OCMA Conference.
      <br></br> <br></br> 
      An interactive group orientation session will show you how rigorous you need to be in order to be a Top Sum Elite mathematics professor. You'll learn the rules of engagement and how to identify maverick tricks from over a dozen of your Ontario college math colleagues.
      <br></br> <br></br> 
      Outside of your studies, you'll build a squadron of peers from other colleges you can call on when your class goes into a tailspin, and you'll strengthen your reflexes through coordination challenges and physical activities. There might even be a volleyball tournament.
      <br></br> <br></br>
      At the end, you'll graduate from Top Sum Elite Mathematics School with increased confidence to tackle math, numeracy, and teaching challenges in your classes. Perhaps your experiences will be inspirational enough for you to become part of Top Sum faculty next year....
      <br></br> <br></br> 
      It's time to join the best of the best. 
      <br></br> <br></br> 
      </div>
      <div class="text-center">  
<StyledLink to="https://drive.google.com/drive/folders/1i72BFRCdJy6aU9XcE4Y1KH-KJEwl7Ub0?usp=sharing">
   <strong>Download Session Descriptions and Schedule</strong>
  </StyledLink>
</div><br></br> 
<hr></hr>
<div class="text-center"
      style={{
        fontSize: '40px',
        display: 'block',
        color: 'black',
      }}
     >
   <strong> $600*</strong>
      </div>
      <div 
      style={{
        fontSize: '16px',
        display: 'block',
        color: 'black',
      }}
     >
       <em>*Early bird rate in effect until April 14th. Includes registration, meals, activities, and 2 nights single accommodation. Rate increases to $650 after April 14th.</em>
      </div>
      <div class="text-center"
                  style={{
                    backgroundColor: '#000',
                    fontSize: '14px',
                    fontWeight: 'normal',
                    textAlign: 'center',
                    padding: '5px 10px',
                    margin: '5px auto 10px',
                    display: 'block',
                    maxWidth: 'auto',
                    color: '#F2F2F2',
                    borderCollapse: 'separate!important',
                    padding: '15px',
                  }}
                >

Final registration and payment is handled directly by Fern Resort.<br></br>
Instructions, full rate details, and conditions are on the registration form.
       
     </div>
     <div class="text-center">  
<StyledLink to="https://forms.gle/oqGSBrmYHqAYH4zF8">
   <strong>Pre-registration Form</strong>
  </StyledLink>
</div><br></br> <br></br> 
</div>  
            
               <br />
                <br />
                <hr />
                <Text h2>In-person: $600</Text>
                <Text>
                  Includes registration, meals, activities, and 2 nights single
                  accommodation.
                </Text>{' '}
                <div
                  style={{
                    backgroundColor: 'grey',
                    fontSize: '15px',
                    padding: '5px 10px',
                    margin: '5px auto 10px',
                    display: 'block',
                    maxWidth: '600px',
                    color: 'white',
                  }}
                >
                  <small>
                    Final registration and payment for in-person attendance is
                    handled directly by Fern Resort. Instructions, full rate
                    details, and conditions are on the registration form.
                  </small>
                </div>
                <StyledLink
                  h4
                  border
                  to="https://docs.google.com/forms/d/e/1FAIpQLSfNrZt_ciEKVwUcBAPsYEr5rt6R4Fu0eHQTyyfDKjJNHj2aFA/viewform"
                >
                  In-Person Pre-registration Form
                </StyledLink>
                <br />
                <br />
                <hr />
                <Text h2>Online: $75</Text>
                <Text>
                  Includes zoom links to keynote speaker and two streams to
                  alternate between the presentations you are interested in.
                </Text>{' '}
                <div
                  style={{
                    backgroundColor: 'grey',
                    fontSize: '15px',
                    padding: '5px 10px',
                    margin: '5px auto 10px',
                    display: 'block',
                    maxWidth: '600px',
                    color: 'white',
                  }}
                >
                  <small>
                    Payment for online attendance is by e-transfer only. Details
                    on the the online attendance registration form.
                  </small>
                </div>
                <StyledLink h4 border to="https://forms.gle/FSX5b5tX1Sqho7ut8">
                  Online Attendance Registration Form
                </StyledLink>
                <br />
                <br /> 
                <hr />*/}
                {/* <br />
                <StyledLink h4 border to="/annual-conference-2024">
                    Learn More
                  </StyledLink> */}
                <br />
                <Text h1>About Us</Text>
                <Text>
                  The Mission of the Ontario Colleges Mathematics Association
                  (OCMA) is to promote and facilitate communication among
                  individuals with an interest in mathematics education in the
                  Colleges of Applied Arts and Technology of the province of
                  Ontario
                </Text>
                <FeatLink to="/about">Learn More</FeatLink>
              </ContentSection>
            </ThemeProvider>
          </div>
        </div>

        <ContentSection>
          <ThemeProvider theme={styles.palette.blue}>
            <div
              className="row"
              css={(theme) =>
                css`
                  background-color: ${theme.bg};
                `
              }
            >
              <div
                className="col"
                css={css`
                  padding: ${styles.margin.mid} ${styles.margin.large};
                  text-align: center;

                  ${styles.mq[1]} {
                    padding: ${styles.margin.mid} ${styles.margin.small};
                  }
                `}
              >
                <Text h4>{acf.assiciation_section_header}</Text>
                <Text
                  css={css`
                    margin-bottom: ${styles.margin.mid};
                  `}
                >
                  {acf.assicoation_section_text}
                </Text>
                <SubscribeModalContextConsumer>
                  {(context) => (
                    <StyledLink
                      h4
                      border
                      invert
                      onClick={context.openFunc}
                      css={css`
                        cursor: pointer;
                      `}
                    >
                      Subscribe
                    </StyledLink>
                  )}
                </SubscribeModalContextConsumer>
                <p style={{ color: 'white', marginTop: 30 }}>
                  Questions? Please contact Corey Pyne at{' '}
                  <a
                    href="mailto:cpyne@stclaircollege.ca"
                    style={{ color: 'white' }}
                  >
                    cpyne@stclaircollege.ca
                  </a>
                </p>
              </div>
            </div>
          </ThemeProvider>
          <div>
            {/* <div
              className="row justify-content-center"
              css={css`
                margin-bottom: 80px;
                padding-top: 32px;
              `}
            >
              <div className="col-md-10 text-center">
                <Text>{acf.mission_text}</Text>
              </div>
            </div> */}
            <div className="row ">
              <ThemeProvider theme={styles.palette.red}>
                <div
                  className="col-lg-5 order-lg-2"
                  css={css`
                    background-color: ${styles.colors.red};
                    /* border-top-left-radius: ${styles.borderRadius.big}; */
                    padding: 72px 48px 48px;
                    /* height: 115%; */

                    /* ${styles.mq[2]} {
                      border-top-left-radius: 0;
                    } */

                    ${styles.mq[1]} {
                      padding: ${styles.margin.mid} ${styles.margin.small};
                    }
                  `}
                >
                  <Text h1>{acf.conference_callout_header}</Text>
                  <Text
                    h3
                    css={css`
                      margin-bottom: ${styles.margin.mid};
                    `}
                  >
                    {acf.conference_callout_subheader}
                  </Text>
                  <Text
                    css={css`
                      margin-bottom: ${styles.margin.mid};
                    `}
                  >
                    {acf.conference_callout_paragraph}
                  </Text>
                  <StyledLink
                    h4
                    border
                    invert
                    to={acf.conference_callout_page_slug}
                  >
                    View Events
                  </StyledLink>
                </div>
              </ThemeProvider>
              <GatsbyBackgroundImage
                fluid={
                  acf.conference_callout_image.localFile.childImageSharp.fluid
                }
                className="col-lg-7 p-0"
              >
                <GatsbyImage
                  fluid={
                    acf.conference_callout_image.localFile.childImageSharp.fluid
                  }
                  css={css`
                    display: none;
                    ${styles.mq[2]} {
                      display: block;
                    }
                  `}
                />
              </GatsbyBackgroundImage>
            </div>
          </div>
        </ContentSection>

        {/* <ContentSection>
          <div className="container text-center">
            {'Join the discussion today all things Canadian mathematics at '}
            <Link to="https://mathquery.ca/">Mathequery.ca</Link>
          </div>
        </ContentSection> */}

        <ContentSection>
          <div className="row justify-content-center">
            <div className="col-md-10">
              <div
                css={css`
                  text-align: right;
                `}
              >
                <Text h1>{acf.gallery_section.gallery_section}</Text>
                <div
                  css={css`
                    display: inline-block;
                    position: relative;
                    margin-bottom: ${styles.margin.small};
                  `}
                >
                  <Text
                    pFeat
                    css={css`
                      text-transform: uppercase;
                      color: ${styles.colors.red};
                      position: relative;
                      display: inline-block;
                      margin-bottom: 0;

                      ${styles.mq[1]} {
                        font-size: ${styles.text.p};
                      }
                    `}
                  >
                    {acf.gallery_section.event_title}
                  </Text>
                  <OcmaColorsBar
                    css={css`
                      position: absolute;
                      width: 100%;
                      bottom: 0;
                      height: 3px;
                    `}
                  />
                </div>
              </div>

              {/* <div
              css={css`
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;

                ${styles.mq[1]} {
                  & img {
                    width: 100%;
                    height: auto;
                    max-height: 100%;
                    display: block;
                  }
                }
              `}
            >
              {acf.gallery
                .filter(image => image.localFile !== null)
                .slice(0, 10)
                .map((galleryImage, i) => (
                  <img
                    key={`indexGallery_${i}`}
                    src={galleryImage.localFile.childImageSharp.fixed.src}
                    alt=""
                    css={css`
                      margin-bottom: ${styles.margin.small};
                    `}
                  />
                ))}
            </div> */}
              <Slider
                {...sliderSettings}
                css={css`
                  margin-bottom: ${styles.margin.small};
                `}
              >
                {acf.gallery
                  .filter((image) => image.localFile !== null)
                  .slice(0, 12)
                  .map((galleryImage, i) => (
                    <div key={`indexGallery_${i}`}>
                      <GatsbyImage
                        fixed={galleryImage.localFile.childImageSharp.fixed}
                      />
                    </div>
                  ))}
              </Slider>
              <div
                css={css`
                  text-align: center;
                `}
              >
                <StyledLink h4 border to={acf.gallery_section.link}>
                  View more
                </StyledLink>
              </div>
            </div>
          </div>
        </ContentSection>
        {/* <ThemeProvider theme={styles.palette.red}>
          <div className="row">
            <div
              css={(theme) => css`
                text-align: center;
                padding: ${styles.margin.mid} ${styles.margin.large};
                background-color: ${theme.bg};
                position: relative;
                width: 100%;

                ${styles.mq[1]} {
                  padding: ${styles.margin.mid} ${styles.margin.small};
                }
              `}
            >
              <Text
                css={css`
                  margin-bottom: ${styles.margin.mid};
                `}
              >
                {acf.subscribe_text}
              </Text>
              <SubscribeModalContextConsumer>
                {(context) => (
                  <StyledLink
                    h4
                    border
                    invert
                    onClick={context.openFunc}
                    css={css`
                      cursor: pointer;
                    `}
                  >
                    Subscribe
                  </StyledLink>
                )}
              </SubscribeModalContextConsumer>
            </div>
          </div>
        </ThemeProvider> */}
      </MainContentWrap>
    </Layout>
  )
}
